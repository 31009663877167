<template>
  <div class="page-container app-vh100" flex>
    <div class="app-mg-auto">
      <el-card style="width: 600px">
        <div class="title app-text-center">System Admin后台管理系统</div>
        <el-form
          :model="formData"
          :rules="rules"
          ref="registerForm"
          @keyup.enter.native="submitForm('registerForm')"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="formData.username"
              placeholder="请输入用户名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="formData.password"
              type="password"
              auto-complete="off"
              placeholder="请输入密码"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="repassword">
            <el-input
              v-model="formData.repassword"
              type="password"
              auto-complete="off"
              placeholder="请输入密码"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <div flex class="app-w100">
              <div flex-box="8">
                <el-input
                  v-model="formData.code"
                  placeholder="请输入验证码"
                  clearable
                ></el-input>
              </div>
              <div flex-box="2" class="app-text-center">
                <img
                  class="img-captcha"
                  :src="captchaUrl"
                  alt="captcha"
                  @click="refreshCaptcha()"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('registerForm')"
              >注册</el-button
            >
            <el-button @click="resetForm('registerForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "login",
  data() {
    var checkPass = (rule, value, callback) => {
      if (this.formData.password && value !== this.formData.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      captchaUrl: "",
      formData: {
        username: "",
        password: "",
        repassword: "",
        code: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repassword: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          { validator: checkPass, trigger: "blur" },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.refreshCaptcha();
  },
  methods: {
    ...mapActions(["login"]),
    refreshCaptcha() {
      this.captchaUrl =
        this.mCommonBaseURL + "/captcha/create" + "?t=" + new Date().getTime();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 验证验证码
          let url = "/captcha/verify?code=" + this.formData.code;
          this.$request
            .get(url, {
              baseURL: this.mCommonBaseURL,
            })
            .then((res) => {
              if (res.code != 0) {
                this.mToast(res.message, "error");
                return false;
              }
              // 进行注册
              let url = "/auth/register";
              let loading = this.$loading();
              this.$request
                .post(url, this.formData, {
                  baseURL: this.mCommonBaseURL,
                })
                .then((res) => {
                  loading.close();
                  if (res.code != 0) {
                    this.mToast(res.message, "error");
                  } else {
                    this.mToast("注册成功", "success", () => {
                      this.$router.push("/");
                    });
                  }
                });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    doLogin() {
      this.login().then(() => {
        this.$alert("login successful", {
          title: "温馨提示",
        }).then(() => {
          this.$router.push("/").catch((err) => {
            console.log(err);
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  padding: 50px 0;
}
.img-captcha {
  border-radius: 2px;
  cursor: pointer;
}
</style>